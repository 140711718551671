import { Controller } from "@hotwired/stimulus"
import { jsonFetch } from "../helpers/json_fetch"
import { formatFloat, formatInt } from "../helpers/format"

export default class extends Controller {
  static values = {
    authUrl: String,
  }

  static targets = [
    "star",
    "form",
    "userRating",
    "userRatingValue",
    "averageValue",
    "countValue",
    "loader",
    "error",
  ]

  connect() {
    this.error = null
    this.loading = false
  }

  init(e) {
    this.data.set("userValue", e.detail.rating)
    this.#update()
  }

  enter(e) {
    this.data.set("currentValue", parseFloat(e.target.value))
    this.#update()
  }

  leave() {
    this.data.delete("currentValue")
    this.#update()
  }

  choose(e) {
    e.preventDefault()

    this.#fetch({
      method: "POST",
      body: {
        value: parseFloat(e.target.closest(".rate__star").value),
      },
    })
  }

  delete(e) {
    e.preventDefault()

    // Prevents the page from following the delete button and scrolling up when
    // it is removed.
    e.target.blur()

    this.#fetch({ method: "DELETE" })
  }

  #fetch = options => {
    // Consider that we are loading if request takes more than 300ms
    // Only handles one request at a time, since there is only one loading state
    const loadingTimeout = setTimeout(() => {
      this.loading = true
      this.#update()
    }, 300)

    jsonFetch(this.formTarget.action, options)
      .then(this.#handleResponse)
      .catch(this.#handleError)
      .then(() => {
        this.loading = false
        clearTimeout(loadingTimeout)
      })
      .then(this.#update)
  }

  #handleResponse = ({ average, count, user, error }) => {
    this.loading = false
    this.error = error || null

    if (average) this.data.set("average", average)
    if (count) this.data.set("count", count)

    if (user) {
      this.data.set("userValue", user)
    } else {
      this.data.delete("userValue")
    }
  }

  #handleError = (response) => {
    if (response.status == 401) {
      window.location = this.authUrlValue
    } else {
      this.error = response.responseJSON.error || "Unknown error"
    }
    return response
  }

  #update = () => {
    this.loaderTarget.hidden = !this.loading
    this.errorTarget.innerText = this.error

    this.#updateUserValue()
    this.#updateAverage()
  }

  #updateUserValue = () => {
    const userValue =
      this.data.get("userValue") && parseFloat(this.data.get("userValue"))

    this.element.classList.toggle("rate--done", !this.loading && userValue)
    this.userRatingTargets.forEach(target => {
      target.hidden = this.loading || !userValue
    })
    this.userRatingValueTargets.forEach(target => {
      target.innerText = formatFloat(userValue || 0)
    })
  }

  #updateAverage = () => {
    const average = parseFloat(this.data.get("average"))
    const count = parseFloat(this.data.get("count"))
    const value = this.data.has("currentValue")
      ? this.data.get("currentValue")
      : average

    this.averageValueTarget.innerText = formatFloat(average)
    this.countValueTarget.innerText = formatInt(count)

    this.starTargets.forEach(target => {
      target.classList.toggle("is-filled", parseFloat(target.value) <= value)
    })
  }
}

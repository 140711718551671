import { Controller } from "@hotwired/stimulus"
import { useHotkeys } from "stimulus-use/hotkeys"
import { turboFetch } from "../helpers/html_fetch"

// The theme-name meta can only be: "light", "dark".
// The color-scheme meta can be: "light dark", "light", "dark".
// The themes can be: "auto", "light", "dark".

export default class extends Controller {
  static values = {
    updateUrl: String,
  }

  connect() {
    // Ensure theme is up to date when this element is replaced on the page.
    this.#themeUpdate()

    // If the preferred browser scheme changes, update the name meta on "auto".
    this.media = window.matchMedia("(prefers-color-scheme: dark)")
    this.media.addEventListener("change", this.#updateNameMetaFromBrowser)

    // Toggle scheme for development.
    useHotkeys(this, { "ctrl+t": [this.#toggleTheme] })
  }

  disconnect() {
    this.media.removeEventListener("change", this.#updateNameMetaFromBrowser)
  }

  #themeUpdate() {
    const theme = this.#schemeToTheme(this.element.content)

    const themes = ["auto", "light", "dark"]
    const classes = document.documentElement.classList
    themes.forEach(t => classes.toggle(`theme-${t}`, t == theme))

    this.#updateNameMetaFromBrowser()
  }

  // We find the theme-name from the color-scheme meta and the browser’s
  // preferred color scheme.
  #updateNameMetaFromBrowser = () => {
    if (this.element.content != "light dark") return
    if (!window.matchMedia) return

    const media = window.matchMedia("(prefers-color-scheme: dark)")
    const meta = document.getElementById("theme-name")
    meta.content = media.matches ? "dark" : "light"
  }

  // Toggle between dark/light themes.
  #toggleTheme() {
    const meta = document.getElementById("theme-name")
    const theme = meta.content == "dark" ? "light" : "dark"
    meta.content = theme
    this.element.content = theme
    this.#saveTheme(theme)
    this.#themeUpdate()
  }

  #schemeToTheme(scheme) {
    return scheme == "light dark" ? "auto" : scheme
  }

  #saveTheme(theme) {
    if (!this.updateUrlValue) return

    turboFetch(this.updateUrlValue, {
      method: "PUT",
      body: { option: { value: theme } },
    })
  }
}

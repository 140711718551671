import { Controller } from "@hotwired/stimulus"
import { show } from "../helpers/hide"

export default class extends Controller {
  static targets = [
    "kind",
    "companyName",
    "countryCode",
    "vin",
    "tin",
  ]

  update() {
    // Updates here should also be applied to the component as hidden defaults
    show(this.companyNameTarget, this.#isCompany())
    show(this.vinTarget, this.#isCompany() && this.#isEuropeanNeighbor())
    show(this.tinTarget, this.#isCompany())
  }

  #isCompany() {
    return this.kindTarget.value == "company"
  }

  #isEuropeanNeighbor() {
    const country = this.countryCodeTarget.selectedOptions[0]
    return country?.dataset?.legalEntityEuropeanNeighbor == "true"
  }
}

import { Controller } from "@hotwired/stimulus"
import { useHotkeys } from "stimulus-use/hotkeys"

// Add a quick hotkey shortcut to a link.
//
// Example:
//   <a
//     data-controller="hotkey-link"
//     data-hotkey-link-key-value="ctrl+a"
//     title="Admin"
//     href="/admin"
//   >
//     Admin
//   </a>
export default class extends Controller {
  static values = { key: String }

  connect() {
    useHotkeys(this, { [this.keyValue]: [this.click] })

    this.element.title = this.#title()
  }

  click() {
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    this.element.dispatchEvent(event)
  }

  #title() {
    const title = this.element.title
    const shortcut = this.#shortcut()

    return title ? `${title} (${shortcut})` : shortcut
  }

  #shortcut() {
    return this.keyValue.split("+").map(v => {
      switch (v) {
        case "cmd": return "⌘"
        case "enter": return "↩"
        default: return v
      }
    }).join(" + ")
  }
}

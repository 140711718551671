import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text"]

  makeDone(e) {
    this.element.href = e.detail.make
    this.textTarget.innerText = this.data.get("addedText")

    const classes = this.element.classList
    classes.add("btn-fourth")
    classes.remove("btn-third")
  }
}

// We could also use the method from rails ujs, however this is not compatible
// with active admin’s import of jquery-ujs.
//
// import { csrfToken } from "@rails/ujs"

const csrfToken = () => {
  return document.querySelector("meta[name=\"csrf-token\"]").content
}

export { csrfToken }

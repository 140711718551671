import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(this.#init, 0)
  }

  #init = () => {
    const rating = this.data.get("rating")
    const make = this.data.get("make")

    if (rating) this.#dispatch("init-creation-rating", { rating })
    if (make) this.#dispatch("init-creation-make", { make })
  }

  #dispatch(name, detail) {
    window.dispatchEvent(new CustomEvent(name, { detail }))
  }
}

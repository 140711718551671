import { Controller } from "@hotwired/stimulus"
import { useHotkeys } from "stimulus-use/hotkeys"

export default class extends Controller {
  connect() {
    useHotkeys(this, {
      "ctrl+g": [this.toggle],
      esc: [this.hide],
    })
  }

  // private

  toggle() {
    if (this.element.hidden) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    document.documentElement.classList.add("is-dev")
    this.element.hidden = false
  }

  hide() {
    document.documentElement.classList.remove("is-dev")
    this.element.hidden = true
  }
}

const hide = (element, hidden = true) => {
  if (hidden) {
    element.setAttribute("hidden", true)
  } else {
    element.removeAttribute("hidden")
  }
}

const show = (element, hidden = false) => hide(element, !hidden)

export { hide, show }

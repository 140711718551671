import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["all", "box"]
  static values = { withConnect: Boolean }

  connect() {
    if (!this.withConnectValue) return

    this.initBoxes()
  }

  checkAll(e) {
    this.boxTargets.forEach(box => box.checked = e.target.checked)
  }

  check() {
    this.allTarget.checked = this.boxTargets.every(box => box.checked)
  }

  initBoxes() {
    const checked = this.allTarget.checked

    this.boxTargets.forEach(box => {
      if (checked) box.checked = true
      box.disabled = checked
    })
  }

  checkAndDisableBoxes() {
    const checked = this.allTarget.checked

    this.boxTargets.forEach(box => {
      box.checked = checked
      box.disabled = checked
    })
  }

  checkBox() {
    const allBoxesChecked = this.boxTargets.every(box => box.checked)

    if (allBoxesChecked) {
      this.allTarget.checked = true
      this.checkAndDisableBoxes()
    } else {
      this.allTarget.checked = false
    }
  }
}
